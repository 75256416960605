import {mapPageToBreadcrumbsItem as mapper} from '../mappers/mapPageToBreadcrumbsItem';
import {RootStore} from '../stores/RootStore';
import {last} from 'lodash';
import {IWixAPI, SiteStructure, StructurePage} from '@wix/yoshi-flow-editor';
import {ReferringPageInfo} from '@wix/wixstores-client-storefront-sdk';
import {SPECS} from '../../../specs';

const isCurrentPage = (wixCodeApi: IWixAPI, pageId: string): boolean => {
  return pageId === wixCodeApi.site.currentPage!.id;
};

const getHomePage = (siteStructure: SiteStructure): StructurePage | undefined => {
  return siteStructure.pages.find((p) => p.isHomePage);
};

const isHomePage = (pageId: string, siteStructure: SiteStructure): boolean => {
  const homepage = getHomePage(siteStructure);
  return !!homepage && pageId === homepage.id;
};

const isValidReferringPage = (rootStore: RootStore, page: ReferringPageInfo) => {
  const {siteStructure} = rootStore.$state;
  const notHomepage = !isHomePage(page.pageId, siteStructure);
  const notSelf = !isCurrentPage(rootStore.wixCodeApi, page.pageId);
  return notHomepage && notSelf;
};

const getReferringItems = (rootStore: RootStore): $w.Breadcrumbs.Item[] => {
  const {referringPage} = rootStore.$state;
  const {isSSR} = rootStore.controllerParams.flowAPI.environment;
  const shouldLocalizeAllProductsTitle = rootStore.isExperimentEnabled(
    SPECS.PRODUCT_PAGE_BREADCRUMBS_LOCALIZE_ALL_PRODUCTS_REFERRING_PAGE,
  );

  if (!isSSR && referringPage && isValidReferringPage(rootStore, referringPage)) {
    const pageName =
      shouldLocalizeAllProductsTitle && referringPage.title === 'All Products'
        ? rootStore.t.productPage.category.allProducts()
        : referringPage.title;

    const referringPageItem = mapper({url: referringPage.url, name: pageName}, !shouldLocalizeAllProductsTitle);
    return [referringPageItem];
  }
  return [];
};

const getCurrentPageItem = (rootStore: RootStore) => {
  const {name} = rootStore.$state.product;
  return {...mapper({name}, false), isCurrent: true};
};

const lastItemIsCurrentPage = (items: $w.Breadcrumbs.Item[]) => {
  return last(items)?.isCurrent;
};

const removeLast = (items: any[]) => {
  return items.slice(0, -1);
};

export const getBreadcrumbsItems = (rootStore: RootStore): $w.Breadcrumbs.Item[] => {
  const defaultItems = rootStore.breadcrumbsInitialItems;
  if (defaultItems.length === 0) {
    return [];
  }
  const referringItems = getReferringItems(rootStore);
  if (lastItemIsCurrentPage(defaultItems)) {
    const currentPageItem = getCurrentPageItem(rootStore);
    return removeLast(defaultItems).concat([...referringItems, currentPageItem]);
  } else {
    return defaultItems.concat(referringItems);
  }
};
